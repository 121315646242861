































































































































































































import Vue from "vue";
import Valid, { EMAIL_REGEX } from "../utils/valid";
import AuthStore from "@/store/modules/AuthStore";
import { PersonaCreateDto } from "@/api/AuthService";

type VForm = Vue & {
  validate: () => boolean;
};
export default Vue.extend({
  metaInfo: {
    title: "Registro"
  },
  data() {
    return {
      fechaHoy: new Date(),
      politicas: false,
      discapacitado: false,
      carnetConadis: "",
      nombre: "",
      apellido: "",
      rules: [(v: string) => !!v || "Campo requerido"],
      sexo: "",
      email: "",
      emailRules: [
        (v: string) => !!v || "Campo requerido",
        (v: string) => EMAIL_REGEX.test(v) || "El correo no es válido"
      ],
      password: "",
      password2: "",
      passRules: [
        (v: string) => !!v || "Campo requerido",
        (v: string) =>
          v.length >= 8 || "La contraseña debe tener por lo menos 8 caracteres"
      ],
      celularRules: [
        (v: string) => !!v || "Campo requerido",
        (v: string) => v.length === 10 || "El celular debe tener 10 números"
      ],

      pass2Rules: [
        (v: string) => !!v || "Campo requerido",
        (v: string) =>
          v === (this.$refs.pass as Vue & { value: string }).value ||
          "Las contraseñas no coinciden"
      ],
      tipoid: "CED",
      identificacion: "",
      // antiguo
      cedulaRules: [
        (v: string) => !!v || "Campo requerido",
        //(v: string) => Valid.identificacionValida(v) || "La cédula no es válida"
        (v: string) =>
          (v.length === 10 && Valid.identificacionValida(v)) ||
          "La cédula no es válida"
      ],
      // nuevo
      carnetConadisRules: [(v: string) => !!v || "Campo requerido"],
      rucRules: [
        (v: string) => !!v || "Campo requerido",
        (v: string) =>
          (v.length === 13 && Valid.identificacionValida(v)) ||
          "El RUC no es válido"
      ],

      // fin
      pasaporteRules: [(v: string) => !!v || "Campo requerido"],
      telefono: "",
      ciudad: "",
      direccion: "",
      referencias: "",
      googlemap: "",
      items: ["Cédula", "Pasaporte", "RUC"],
      identidadItems: [
        { text: "Cédula", value: "CED" },
        { text: "RUC", value: "RUC" },
        { text: "Pasaporte", value: "PAS" }
      ],
      ///sexoitems: ["Masculino", "Femenino"],
      sexoItems: [
        { text: "Masculino", value: "M" },
        { text: "Femenino", value: "F" }
      ],
      ciudades: ["Guayaquil", "Manta", "Salinas", "Quito"],
      loading: false,
      date: "2000-01-01", //new Date().toISOString().substr(0, 10),
      modal: false
    };
  },
  methods: {
    sendForm() {
      //(this.$refs.form as VForm).validate();
      if ((this.$refs.form as VForm).validate()) {
        const createDto: PersonaCreateDto = {
          correo: this.email,
          tipoIdentificacion: this.tipoid,
          identificacion: this.identificacion,
          nombre: this.nombre,
          apellido: this.apellido,
          sexo: this.sexo,
          telefono: this.telefono,
          fechaNacimiento: this.date
        };

        if (this.discapacitado) {
          createDto.carnetConadis = this.carnetConadis;
        }
        AuthStore.register(createDto)
          .then(() => {
            this.$swal({
              icon: "success",
              title: "Registro Exitoso",
              text: "Se envio la contraseña a su correo"
            }).then(() => {
              this.$router.push("/login");
            });
          })
          .catch(() => {
            this.$swal({
              toast: true,
              position: "bottom-right",
              showConfirmButton: false,
              timer: 1500,
              icon: "error",
              title: "Registro",
              text: "Fallido, usted ya tiene cuenta"
            });
          });
      }
    },
    getIdRules() {
      return null;
    },
    saveFecha(date: any) {
      console.log(date);
    }
  },
  watch: {
    nombre: function(newVal: string) {
      this.nombre = newVal.toUpperCase();
    },
    apellido: function(newVal: string) {
      this.apellido = newVal.toUpperCase();
    },
    email: function(newVal: string) {
      this.email = newVal.toLowerCase();
    }
  },
  computed: {
    getmaxDate: {
      get(): any {
        const endDate = new Date(
          this.fechaHoy.getFullYear() - 2,
          this.fechaHoy.getMonth(),
          this.fechaHoy.getDate()
        );
        return endDate.toISOString().slice(0, 10);
      }
    },
    getIdentidadRules: {
      get(): any {
        switch (this.tipoid) {
          case "CED":
            return this.cedulaRules;
          case "RUC":
            return this.rucRules;
          default:
            return this.pasaporteRules;
        }
      }
    } //end get identidadRules
  }
});
