

























































































import CuentaBancariaService, {
  CuentaBancariaCreateDto
} from "@/api/CuentaBancariaService";
import Vue from "vue";
export default Vue.extend({
  data() {
    return {
      cuentasBancarias: [] as CuentaBancariaCreateDto[],
      headers: [
        {
          text: "#",
          align: "start",
          value: "id"
        },
        { text: "Banco", value: "banco" },
        { text: "Tipo Cuenta", value: "tipoCuenta" },
        { text: "Cuenta #", value: "numero" },
        { text: "Titular", value: "titular" },
        { text: "Tipo Identificación", value: "tipoIdentificacion" },
        { text: "Identificación", value: "identificacion" }
      ]
    };
  },
  methods: {
    getMetodosPago() {
      CuentaBancariaService.getCuentasDisponibles().then(data => {
        this.cuentasBancarias = data;
      });
    }
  },
  mounted() {
    //
    this.getMetodosPago();
  }
});
