





import Vue from "vue";
import CartStore from "@/store/modules/CartStore";
import ProcesoPagoConEnvio from "@/components/web/Pago/proceso-pago/ProcesoPagoConEnvio.vue";
//import ProcesoPagoSinEnvio from "@/components/web/Pago/proceso-pago/ProcesoPagoSinEnvio.vue";
import ProcesoPagoSinEnvioSinFacturacion from "@/components/web/Pago/proceso-pago/ProcesoPagoSinEnvioSinFacturacion.vue";
export default Vue.extend({
  components: {
    ProcesoPagoConEnvio,
    //ProcesoPagoSinEnvio,
    ProcesoPagoSinEnvioSinFacturacion
  },
  computed: {
    /*
    totalITemsCart() {
      return CartStore.getCartItems.length > 0;
    },
    */
    necesitaEnvio() {
      return CartStore.getCartNecesitaEnvio;
    }
  }
});
