






























import Vue from "vue";
import { AxiosError } from "axios";
import InformacionEnvio from "@/components/web/Pago/InformacionEnvio.vue";
import InformacionFacturacion from "@/components/web/Pago/InformacionFacturacion.vue";
import CartStore from "@/store/modules/CartStore";
import PedidoService from "@/api/pedido/PedidoService";
export default Vue.extend({
  components: {
    InformacionEnvio,
    InformacionFacturacion
  },
  data() {
    return {
      e1: 1,
      informacionEnvio: {
        tipoIdentificacion: "",
        identificacion: "",

        nombre: "",
        apellido: "",

        provincia: "",
        canton: "",
        direccion: "",

        telefono: "",

        referencia: "",
        ubicacion: "" //
      },
      informacionFacturacion: {
        tipoIdentificacion: "",
        identificacion: "",

        nombre: "",
        apellido: "",

        provincia: "",
        canton: "",
        direccion: "",

        telefono: ""
      },
      pedidoGenerado: { id: 0 }
    };
  },

  created() {
    //
    this.loadDirecciones();
  },

  methods: {
    toFacturacion(v: boolean) {
      if (v) {
        CartStore.actualizarDireccionEnvio(this.informacionEnvio);
        this.e1 = 2;
      }
    },
    toConfirmar(v: boolean) {
      if (v) {
        CartStore.actualizarDireccionFacturacion(this.informacionFacturacion);

        const dto = {
          items: CartStore.getCartItems,
          direccionEnvio: this.informacionEnvio,
          direccionFacturacion: this.informacionFacturacion
        };
        this.toCrearPedido(dto);
      }
    },
    toCrearPedido(dto: any) {
      // llamar al endpoing de creacion
      PedidoService.crearPedido(dto)
        .then(response => {
          this.pedidoGenerado = response;
        })
        .catch((error: AxiosError) => {
          // swal para mostrar error
          let mensaje = "no se pudo crear el pedido, reintente más tarde";
          if (error.response?.data && error.response.data.message) {
            mensaje = error.response.data.message;
          }
          this.$swal({
            toast: true,
            position: "center",
            showConfirmButton: false,
            timer: 4000,
            icon: "error",
            title: "Pedido",
            text: mensaje
          });
        })
        .finally(() => {
          if (this.pedidoGenerado && this.pedidoGenerado.id) {
            const id = `${this.pedidoGenerado.id}`;
            console.log(id);
            this.toSubirRecibo(id);
          }
        });
    },
    toSubirRecibo(id: string) {
      this.$router.push({
        path: "/proceso-pago/subir-papeleta",
        query: { idPedido: id }
      });
    },
    loadDirecciones() {
      this.informacionEnvio = CartStore.getDireccionEnvio;
      this.informacionFacturacion = CartStore.getDireccionFacturacion;
    }
  },
  computed: {
    totalITemsCart() {
      return CartStore.getCartItems.length > 0;
    },
    necesitaEnvio() {
      return CartStore.getCartNecesitaEnvio;
    }
  }
});
