<template>
  <v-btn x-large color="success" @click="pagar()" :loading="!paymentCheckout">
    <v-icon left>mdi-credit-card-outline</v-icon>
    Pagar con Tarjeta
  </v-btn>
</template>

<script>
export default {
  //
  //props: [""],
  props: {
    userID: {
      type: Number,
      default: 0
    },
    description: String,
    reference: String,
    //
    amount: Number,
    // optional
    vat: {
      type: Number,
      default: 0
    },
    taxPercentage: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      paymentCheckout: undefined,
      //
      paymentezEnvMode: process.env.VUE_APP_PAYMENTEZ_ENV_MODE,
      paymentezClientAppCode: process.env.VUE_APP_PAYMENTEZ_CLIENT_APP_CODE,
      paymentezClientAppKey: process.env.VUE_APP_PAYMENTEZ_CLIENT_APP_KEY
    };
  },
  methods: {
    pagar() {
      this.paymentCheckout.open({
        // eslint-disable-next-line
        user_id: `${this.userID}`,
        // eslint-disable-next-line
        user_email: "", //optional
        // eslint-disable-next-line
        user_phone: "", //optional
        // eslint-disable-next-line
        order_description: this.description,
        // eslint-disable-next-line
        order_amount: this.amount,
        // eslint-disable-next-line
        order_vat: this.vat,
        // eslint-disable-next-line
        order_reference: this.reference, //"#234323411",
        // eslint-disable-next-line
        //order_installments_type: 2, // optional: The installments type are only available for Equador. The valid values are: https://paymentez.github.io/api-doc/#installments-type
        // eslint-disable-next-line
        //order_taxable_amount: 0, // optional: Only available for Datafast (Equador). The taxable amount, if it is zero, it is calculated on the total. Format: Decimal with two fraction digits.
        // eslint-disable-next-line
        order_tax_percentage: this.taxPercentage // optional: Only available for Datafast (Equador). The tax percentage to be applied to this order.
      });
    }
  },
  mounted() {
    //foo();
    //
    //var self = this;
    console.log("iniciando el plugin");
    const externalScript = document.createElement("script");
    // eslint-disable-next-line
    const self = this;
    externalScript.onload = () => {
      console.log("recien cargando");
      // eslint-disable-next-line
      this.paymentCheckout = new PaymentCheckout.modal({
        //process.env.VUE_APP_NOT_SECRET_CODE
        // eslint-disable-next-line
        client_app_code: this.paymentezClientAppCode, // Client Credentials
        // eslint-disable-next-line
        client_app_key: this.paymentezClientAppKey, // Client Credentials
        locale: "es", // User's preferred language (es, en, pt). English will be used by default.
        // eslint-disable-next-line
        env_mode: this.paymentezEnvMode, // `prod`, `stg`, `local` to change environment. Default is `stg`
        onOpen: function() {
          console.log("modal open");
        },
        onClose: function() {
          console.log("modal closed");
        },
        onResponse: function(response) {
          console.log(response);
          if (
            response &&
            response.transaction &&
            response.transaction.status
            // failure vs success or pending
            //response.transaction.status === "success"
          ) {
            self.$emit("onResponse", response);
          }
        }
      });
      console.log("si cargo");
    };

    /*
    externalScript.onload = () => {
      console.log("hgola");
      // eslint-disable-next-line no-undef
      const paymentCheckout = PaymentCheckout.modal({
        // eslint-disable-next-line
        client_app_code: "TPP3-EC-CLIENT", // Client Credentials
        // eslint-disable-next-line
        client_app_key: "ZfapAKOk4QFXheRNvndVib9XU3szzg", // Client Credentials
        locale: "es", // User's preferred language (es, en, pt). English will be used by default.
        // eslint-disable-next-line
        env_mode: "stg", // `prod`, `stg`, `local` to change environment. Default is `stg`
        onOpen: function() {
          console.log("modal open");
        },
        onClose: function() {
          console.log("modal closed");
        }
      });
      //paymentCheckout.open({});
    };
    */
    externalScript.setAttribute(
      "src",
      "https://cdn.paymentez.com/ccapi/sdk/payment_checkout_stable.min.js"
    );
    document.head.appendChild(externalScript);
  }
};
</script>
