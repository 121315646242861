














































































































































import Vue from "vue";
import Valid from "@/utils/valid";
import ProvinciaService from "@/api/pedido/ProvinciaService";
export default Vue.extend({
  props: ["value"],
  data() {
    return {
      valid: true,
      editedItem: {
        tipoIdentificacion: "",
        identificacion: "",

        nombre: "",
        apellido: "",

        provincia: "",
        canton: "",
        direccion: "",

        telefono: ""
      },
      tipoIdentificacionOptions: [
        { text: "Cédula", value: "CED" },
        { text: "RUC", value: "RUC" },
        { text: "Pasaporte", value: "PAS" }
      ],
      TextRules: [(v: string) => !!v || "Campo no debe estar vacio"],
      email: "",
      emailRules: [
        (v: string) => !!v || "E-mail is required",
        (v: string) => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ],

      pasaporteRules: [(v: string) => !!v || "Campo requerido"],
      checkbox: false,
      provincias: [] as any
    };
  },

  methods: {
    onChangeProvincia() {
      this.editedItem.canton = "";
    },
    loadProvincias() {
      this.provincias = ProvinciaService.getProvincias();
    },
    validate() {
      console.log(this.editedItem);
      //this.$refs.form.validate();
      const isValid = (this.$refs.form as Vue & {
        validate: () => boolean;
      }).validate();
      if (isValid) {
        this.continuar();
      }
    },
    continuar() {
      this.$emit("isComplete", true);
    }
  },
  computed: {
    getCantones(): any {
      const data = this.provincias.filter(
        (c: any) => c.nombre === this.editedItem.provincia
      );
      if (data.length > 0) {
        return data[0].cantones;
      }
      return [];
    },
    getIdentidadRules: {
      get() {
        switch (this.editedItem.tipoIdentificacion) {
          case "CED":
            return [
              (v: string) => !!v || "Campo requerido",
              //(v: string) => Valid.identificacionValida(v) || "La cédula no es válida"
              (v: string) =>
                (v.length === 10 && Valid.identificacionValida(v)) ||
                "La cédula no es válida"
            ];
          case "RUC":
            return [
              (v: string) => !!v || "Campo requerido",
              (v: string) =>
                (v.length === 13 && Valid.identificacionValida(v)) ||
                "El RUC no es válido"
            ];
          default:
            return [(v: string) => !!v || "Campo requerido"];
        }
      }
    } //end get identidadRules
  },

  watch: {
    "editedItem.nombre": function(newVal: string) {
      this.editedItem.nombre = newVal.toUpperCase();
    },
    "editedItem.apellido": function(newVal: string) {
      this.editedItem.apellido = newVal.toUpperCase();
    },
    "editedItem.direccion": function(newVal: string) {
      this.editedItem.direccion = newVal.toUpperCase();
    }
  },

  created() {
    //this.editedItem = ;
    this.loadProvincias();
    this.editedItem = this.value; //mala practica pero como todo es limpio se value el binding doble
  }
});
